// hook that calls callback when user clicks outside of the passed ref

import { useEffect } from 'react';

interface UseClickOutsideProps {
  ref: React.RefObject<HTMLElement>;
  callback: () => void;
  exceptions?: React.RefObject<HTMLElement>[];
}

export const useClickOutside = ({
  ref,
  callback,
  exceptions
}: UseClickOutsideProps) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!exceptions) {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback();
        }
        return;
      } else {
        if (
          ref.current &&
          !ref.current.contains(event.target as Node) &&
          !exceptions.some((exception) =>
            exception.current?.contains(event.target as Node)
          )
        ) {
          callback();
        }
        return;
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback, exceptions]);
};
