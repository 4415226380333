import React, { useState } from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FiMenu } from 'react-icons/fi';

import * as Styled from './App.styles';
import AppStore from './assets/app_store.png';
import Logo from './assets/cxmmunal-logo.svg';
import GooglePlay from './assets/google_play.png';
import Phone from './assets/phone.png';
import { useClickOutside } from './hooks/useClickOutside';
import { useDimensions } from './hooks/useDimensions';

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerRef, setDrawerRef] = useState<HTMLDivElement | null>(null);
  const [burgerRef, setBurgerRef] = useState<HTMLDivElement | null>(null);
  const { width } = useDimensions();

  useClickOutside({
    ref: { current: drawerRef },
    callback: () => setDrawerOpen(false),
    exceptions: [{ current: burgerRef }]
  });

  const isMobile = width <= Styled.TABLET_BREAKPOINT;
  const isTablet = width <= Styled.DESKTOP_BREAKPOINT;

  return (
    <Styled.Container>
      <Styled.Content>
        <Styled.Navbar>
          {!isMobile ? (
            <Styled.Logo src={Logo} alt="Cxmmunal Logo" />
          ) : (
            <Styled.Logo
              src={'https://cxmmunal-images.s3.amazonaws.com/Name+logo+(4).svg'}
              alt="Cxmmunal Logo"
            />
          )}
          {!isMobile ? (
            <Styled.NavLinks>
              <Styled.NavLink
                href="https://mini-iris-f7a.notion.site/Mission-ac6e84bc6d914b63a3aaa02406c623f5?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Mission
              </Styled.NavLink>
              <Styled.NavLink
                href="https://mini-iris-f7a.notion.site/Team-57387fcf2cca44719054a6e8ce8a697e?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Team
              </Styled.NavLink>
              <Styled.NavLink
                href="https://mini-iris-f7a.notion.site/Partnerships-b7c9145502b84e99901e3990bdecdb4a?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Partnerships
              </Styled.NavLink>
              <Styled.NavLink
                href="https://mini-iris-f7a.notion.site/Ambassadors-1f2c8999208041d4abbe60af829262c8?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ambassadors
              </Styled.NavLink>
            </Styled.NavLinks>
          ) : (
            <Styled.Burger
              active={drawerOpen}
              onClick={() => setDrawerOpen(!drawerOpen)}
              ref={setBurgerRef}
            >
              <FiMenu size={28} color="white" />
            </Styled.Burger>
          )}
          <div />
        </Styled.Navbar>
        {isMobile && (
          <Styled.Drawer open={drawerOpen} ref={setDrawerRef}>
            <Styled.NavLink
              href="https://mini-iris-f7a.notion.site/Mission-ac6e84bc6d914b63a3aaa02406c623f5?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mission
            </Styled.NavLink>
            <Styled.NavLink
              href="https://mini-iris-f7a.notion.site/Team-57387fcf2cca44719054a6e8ce8a697e?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Team
            </Styled.NavLink>
            <Styled.NavLink
              href="https://mini-iris-f7a.notion.site/Partnerships-b7c9145502b84e99901e3990bdecdb4a?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Partnerships
            </Styled.NavLink>
            <Styled.NavLink
              href="https://mini-iris-f7a.notion.site/Ambassadors-1f2c8999208041d4abbe60af829262c8?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Ambassadors
            </Styled.NavLink>
          </Styled.Drawer>
        )}
        <Styled.Body>
          <Styled.LHS>
            <Styled.Title>Welcome to your modern Cxmmunity</Styled.Title>
            {isMobile && (
              <>
                <br />
                <br />
                <br />
              </>
            )}
            <Styled.Description>
              Launch magical experiences.{' '}
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}
              Get discounts on fun.{' '}
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}
              Meet {!isTablet && <br />}
              amazing new people in your city.
              <br />
              <br />
              <br />
              {isMobile && (
                <>
                  <br />
                  <br />
                </>
              )}
              Created in response to the loneliness epidemic.
              <br />
              <br />
              {!isMobile && <br />}
              <a
                href="https://mini-iris-f7a.notion.site/Mission-ac6e84bc6d914b63a3aaa02406c623f5?pvs=4"
                target="_blank"
                rel="noopener noreferrer"
              >
                Read about our mission.
              </a>
            </Styled.Description>
            <Styled.ButtonRow>
              <a
                href="https://apps.apple.com/us/app/cxmmunal/id6458728878"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.StoreButton src={AppStore} alt="Google Play" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.cxmmunal.cxmmunal"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Styled.StoreButton src={GooglePlay} alt="Google Play" />
              </a>
            </Styled.ButtonRow>
          </Styled.LHS>
          <Styled.Phone src={Phone} alt="Cxmmunal Phone" />
        </Styled.Body>
        <Styled.Footer>
          <Styled.NavLinks>
            <Styled.NavLink
              href="https://mini-iris-f7a.notion.site/Privacy-Policy-acd99f99c89b4803b67b5e0676beb596?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Styled.NavLink>
            <Styled.NavLink
              href="https://mini-iris-f7a.notion.site/Contact-4dd5a2a367824a2f8be843d572c420aa?pvs=4"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact
            </Styled.NavLink>
          </Styled.NavLinks>
          <a
            href="https://www.instagram.com/cxmmunal/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <BsInstagram size={isMobile ? 24 : 32} color="white" />
          </a>
        </Styled.Footer>
      </Styled.Content>
    </Styled.Container>
  );
}

export default App;
