// return the dimensions of the window
import _ from 'lodash';
import { useCallback, useState } from 'react';

export const useDimensions = () => {
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };

  const debouncedHandleResize = useCallback(_.debounce(handleResize, 100), []);

  window.addEventListener('resize', debouncedHandleResize);

  return dimensions;
};
