import styled from 'styled-components';

export const DESKTOP_BREAKPOINT = 1150;
export const TABLET_BREAKPOINT = 768;

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 25px;
  box-sizing: border-box;
`;

export const Content = styled.div`
  width: 100%;
  flex: 1;
  max-width: 1150px;
  display: flex;
  flex-direction: column;

  @media (max-width: ${DESKTOP_BREAKPOINT}px) {
    max-width: 768px;
  }

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: unset;
    align-items: center;
  }
`;

export const Navbar = styled.div`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  width: 100%;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > :last-child {
      display: none;
    }
  }
`;

export const Logo = styled.img`
  width: 70px;
  height: auto;

  @media (max-width: ${DESKTOP_BREAKPOINT}px) {
    width: 50px;
  }

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    width: 120px;
  }
`;

export const Burger = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  padding: 8px;

  transition: background-color 0.2s ease-in-out;

  ${({ active }) => active && `background-color: rgba(255, 255, 255, 0.1);`}
`;

export const Drawer = styled.div<{ open: boolean }>`
  ${({ open }) => `
    max-height: ${open ? '200px' : '0'};
  `}
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;

  display: flex;
  flex-direction: column;
  align-items: center;

  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > :not(:last-child) {
    margin-right: 100px;
  }

  @media (max-width: ${DESKTOP_BREAKPOINT}px) {
    > :not(:last-child) {
      margin-right: 50px;
    }
  }

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    > :not(:last-child) {
      margin-right: 24px;
    }
  }
`;

export const NavLink = styled.a`
  font-weight: 500;
  font-size: 20px;

  @media (max-width: ${DESKTOP_BREAKPOINT}px) {
    font-size: 16px;
  }
`;

export const Body = styled.div`
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 25px;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    max-width: unset;
    align-items: center;
    column-gap: 0;
  }
`;

export const LHS = styled.div`
  > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  font-size: 55px;
  font-weight: 600;

  @media (max-width: ${DESKTOP_BREAKPOINT}px) {
    font-size: 40px;
  }

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 24px;
    text-align: center;
  }
`;

export const Description = styled.div`
  font-size: 18px;

  a {
    font-weight: 700;
  }

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    font-size: 16px;
    text-align: center;
  }
`;

export const Phone = styled.img`
  width: 320px;
  height: auto;

  @media (max-width: ${DESKTOP_BREAKPOINT}px) {
    width: 250px;
  }

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    display: none;
  }
`;

export const ButtonRow = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > :not(:last-child) {
    margin-right: 12px;
  }

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
    margin-top: 90px;

    > :not(:last-child) {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }
`;

export const StoreButton = styled.img`
  height: 47px;
  width: auto;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    height: 50px;
  }
`;

export const Footer = styled.div`
  margin-top: auto;
  padding-top: 40px;
  display: grid;
  grid-template-columns: 1fr auto;
  column-gap: 24px;
  align-items: center;

  @media (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;
